import logo from './logo.svg';
import './App.css';
import React from "react";
import { Unity, useUnityContext } from "react-unity-webgl";


function App() {
  const { unityProvider } = useUnityContext({
    loaderUrl: "Build/starlog.loader.js",
    dataUrl: "Build/starlog.data",
    frameworkUrl: "Build/starlog.framework.js",
    codeUrl: "Build/starlog.wasm",
  });
  return <Unity unityProvider={unityProvider} style={{width: '100vw', height: '100vh'}} />;
}

export default App;
